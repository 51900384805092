import { type TypedDocumentNode, gql } from '@apollo/client'

import type {
  UpdateProfileAvatarMutation,
  UpdateProfileAvatarMutationVariables,
} from './updateProfileAvatar.generated'

export const UPDATE_PROFILE_AVATAR_MUTATION: TypedDocumentNode<
  UpdateProfileAvatarMutation,
  UpdateProfileAvatarMutationVariables
> = gql`
  mutation UpdateProfileAvatar($input: UpdateUserProfileInput!) {
    updateProfile(updateProfileInput: $input) {
      id
      avatarUrl
    }
  }
`
