import type { RouteObject } from 'react-router-dom'

import { AuthProtectedRoute } from '../helpers/AuthProtectedRoute'
import { createRoleProtectedRouteObject } from '../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { AuthLayout } from '@/layouts'
import {
  userRequiresApplication,
  userWaitingOnApplication,
} from '@/lib/loginFlowHelpers'

export const authRoutes: RouteObject[] = [
  {
    element: <AuthLayout />,
    children: [
      {
        element: (
          <AuthProtectedRoute
            allowIf={(auth) => userRequiresApplication(auth)}
          />
        ),
        children: [
          createRoleProtectedRouteObject(
            routes.createProfile,
            async () => await import('@/pages/auth/CreateProfile'),
          ),
        ],
      },
      {
        element: (
          <AuthProtectedRoute
            allowIf={(auth) => userWaitingOnApplication(auth)}
          />
        ),
        children: [
          createRoleProtectedRouteObject(
            routes.applicationPending,
            async () => await import('@/pages/auth/ApplicationPending'),
          ),
        ],
      },
      createRoleProtectedRouteObject(
        routes.acceptInvite,
        async () => await import('@/pages/auth/AcceptInvitePage'),
      ),
    ],
  },
]
