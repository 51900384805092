import CloseIcon from '@mui/icons-material/Close'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import type { PropsWithChildren } from 'react'

export interface CardModalProps extends PropsWithChildren {
  title?: string
  open: boolean
  onClose: () => void
}

export function CardModal({
  title = 'Alert',
  open,
  onClose,
  children,
}: CardModalProps) {
  return (
    <Modal open={open}>
      <Card
        sx={{
          position: 'absolute',
          width: { xs: 450, md: 600 },
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CardHeader
          title={title}
          action={
            <IconButton onClick={onClose} size="large" aria-label="close modal">
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />

        <Divider />

        {children}
      </Card>
    </Modal>
  )
}
