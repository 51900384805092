import Box from '@mui/material/Box'
import Button, { type ButtonProps } from '@mui/material/Button'

import { LoadingSpinner } from './LoadingSpinner'

interface ButtonWithSpinnerProps extends ButtonProps {
  loading?: boolean
}

export function ButtonWithSpinner({
  loading = false,
  ...rest
}: ButtonWithSpinnerProps) {
  return (
    <Box position="relative">
      <Button disabled={loading} {...rest} />
      {loading && (
        <Box
          sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <LoadingSpinner size={30} />
        </Box>
      )}
    </Box>
  )
}
