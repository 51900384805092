import { useLazyQuery, useMutation } from '@apollo/client'
import Box from '@mui/material/Box'
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { signOut as stSignOut } from 'supertokens-auth-react/recipe/session'

import { AuthenticationContext } from './AuthenticationContext'
import { CURRENT_USER_QUERY } from './gql/currentUserQuery'
import { UPDATE_ME_MUTATION } from './gql/updateMe'
import type { AuthenticationContextValue } from './types'
import { useSessionData } from './useSessionData'

import { LoadingSpinner } from '@/components/LoadingSpinner'
import { type AuthRedirectParams, redirectToAuth } from '@/lib/loginFlowHelpers'

export interface AuthProviderProps {
  children?: ReactNode
}

export function AuthenticationProvider({ children }: AuthProviderProps) {
  const [currentUser, setCurrentUser] =
    useState<AuthenticationContextValue['currentUser']>()
  const [signingOut, setSigningOut] = useState<boolean>(false)

  const sessionData = useSessionData()

  const [getCurrentUser, { data, error }] = useLazyQuery(CURRENT_USER_QUERY, {
    fetchPolicy: 'network-only', // always get up-to-date user
  })
  const [updateMe] = useMutation(UPDATE_ME_MUTATION)

  const signOut = useCallback(
    async (
      authParams: AuthRedirectParams = [
        ['redirectUrl', import.meta.env.VITE_HOST_URL],
      ],
    ) => {
      setSigningOut(true)
      await stSignOut()
      redirectToAuth(authParams)
    },
    [],
  )

  useEffect(() => {
    if (sessionData == null) {
      return
    }
    if (sessionData.session.doesSessionExist) {
      void getCurrentUser()
    } else {
      setCurrentUser(null)
    }
  }, [getCurrentUser, sessionData])

  useEffect(() => {
    if (data != null) {
      setCurrentUser(data.me)
    }
  }, [data])

  // Update timezone for the user if it isn't already set.
  useEffect(() => {
    if (currentUser != null && currentUser.timeZone == null) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      void updateMe({ variables: { input: { timeZone } } })
    }
  }, [currentUser, updateMe])

  const value: AuthenticationContextValue | undefined = useMemo(
    () =>
      currentUser === undefined || sessionData === undefined
        ? undefined
        : {
            currentUser,
            signOut,
            ...sessionData,
          },
    [currentUser, sessionData, signOut],
  )

  if (error != null) {
    throw error
  }

  return value == null || signingOut ? (
    <Box height="100vh">
      <LoadingSpinner />
    </Box>
  ) : (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  )
}
