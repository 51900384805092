import './configs/mui/license'
import './configs/supertokens'

import * as Sentry from '@sentry/react'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'

import { App } from '@/App'

if (import.meta.env.VITE_SENTRY_DSN != null) {
  const tracesSampleRate = parseFloat(
    import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? '1.0',
  )
  const replaysSessionSampleRate = parseFloat(
    import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? '0.1',
  )
  const replaysOnErrorSampleRate = parseFloat(
    import.meta.env.VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE ?? '1.0',
  )

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate,
    tracePropagationTargets: [import.meta.env.VITE_GRAPHQL_API_URL],
    replaysSessionSampleRate,
    replaysOnErrorSampleRate,
  })
}

if (window.location.pathname.includes('//')) {
  window.location.pathname = window.location.pathname.replace(/\/{2,}/, '/')
} else {
  const rootElement = document.getElementById('root')
  if (rootElement != null) {
    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    )
  }
}
