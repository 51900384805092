import FilterAltIcon from '@mui/icons-material/FilterAlt'
import InfoIcon from '@mui/icons-material/Info'
import DuplicatesIcon from '@mui/icons-material/PeopleAlt'
import ScheduleIcon from '@mui/icons-material/Schedule'
import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { DetailsLayout } from '@/layouts'

export const leadCampaignsRoutes: RouteObject[] = [
  createRoleProtectedRouteObject(
    routes.leadCampaigns,
    async () => await import('@/pages/leadManagement/CampaignsPage'),
  ),
  {
    element: (
      <DetailsLayout
        tabs={[
          {
            label: 'General',
            icon: <InfoIcon />,
            linkTo: routes.leadCampaignGeneral.path,
          },
          {
            label: 'Filters',
            icon: <FilterAltIcon />,
            linkTo: routes.leadCampaignFilters.path,
          },
          {
            label: 'Schedule',
            icon: <ScheduleIcon />,
            linkTo: routes.leadCampaignSchedules.path,
          },
          {
            label: 'Duplicates',
            icon: <DuplicatesIcon />,
            linkTo: routes.leadCampaignDuplicates.path,
          },
        ]}
      />
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.leadCampaignGeneral,
        async () => await import('@/pages/leadManagement/CampaignGeneralPage'),
      ),
      createRoleProtectedRouteObject(
        routes.leadCampaignFilters,
        async () => await import('@/pages/leadManagement/CampaignFiltersPage'),
      ),
      createRoleProtectedRouteObject(
        routes.leadCampaignSchedules,
        async () => await import('@/pages/leadManagement/CampaignSchedulePage'),
      ),
      createRoleProtectedRouteObject(
        routes.leadCampaignDuplicates,
        async () =>
          await import('@/pages/leadManagement/CampaignDuplicatesPage'),
      ),
    ],
  },
]
