import type { RouteObject } from 'react-router-dom'

import { AuthProtectedRoute } from '../helpers/AuthProtectedRoute'
import { createRoleProtectedRouteObject } from '../helpers/createRoleProtectedRouteObject'

import { accountSettingsRoutes } from './accountSettings'
import { buyerManagementRoutes } from './buyerManagement'
import { calendarRoutes } from './calendar'
import { contactsRoutes } from './contacts'
import { leadManagementRoutes } from './leadManagement'
import { marketingRoutes } from './marketing'
import { paymentRoutes } from './payment'
import { publisherManagementRoutes } from './publisherManagement'
import { reportsRoutes } from './reports'
import { salesRoutes } from './sales'
import { systemManagementRoutes } from './systemManagement'

import { routes } from '@/configs/routes'
import { MainLayout } from '@/layouts'
import { userHasProfile } from '@/lib/loginFlowHelpers'

export const mainRoutes: RouteObject[] = [
  {
    element: <AuthProtectedRoute allowIf={(auth) => userHasProfile(auth)} />,
    children: [
      {
        element: <MainLayout />,
        children: [
          createRoleProtectedRouteObject(
            routes.dashboard,
            async () => await import('@/pages/DashboardPage'),
          ),
          createRoleProtectedRouteObject(
            routes.newBusinesses,
            async () => await import('@/pages/UnimplementedPage'),
          ),
          createRoleProtectedRouteObject(
            routes.inForcePolicies,
            async () => await import('@/pages/InForcePolicies'),
          ),
          createRoleProtectedRouteObject(
            routes.administration,
            async () => await import('@/pages/UnimplementedPage'),
          ),
          createRoleProtectedRouteObject(
            routes.integrations,
            async () => await import('@/pages/UnimplementedPage'),
          ),
          ...reportsRoutes,
          ...contactsRoutes,
          ...calendarRoutes,
          ...salesRoutes,
          ...marketingRoutes,
          ...leadManagementRoutes,
          ...publisherManagementRoutes,
          ...buyerManagementRoutes,
          ...systemManagementRoutes,
          ...accountSettingsRoutes,
          ...paymentRoutes,
        ],
      },
    ],
  },
]
