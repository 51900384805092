import type { AuthenticationContextValue } from '@/providers/AuthenticationProvider/types'

export type AuthRedirectParamKeys = 'redirectUrl' | 'error' | 'show'
export type AuthRedirectParams = Array<[AuthRedirectParamKeys, string]>

export function userLoggedIn(auth: AuthenticationContextValue): boolean {
  return auth.session.doesSessionExist
}

export function userHasProfile(auth: AuthenticationContextValue): boolean {
  return auth.currentUser?.profile != null
}

export function userHasApplication(auth: AuthenticationContextValue): boolean {
  return auth.currentUser?.agentApplications.edges[0] != null
}

export function userRequiresApplication(
  auth: AuthenticationContextValue,
): boolean {
  return (
    userLoggedIn(auth) && !userHasProfile(auth) && !userHasApplication(auth)
  )
}

export function userWaitingOnApplication(
  auth: AuthenticationContextValue,
): boolean {
  return userLoggedIn(auth) && !userHasProfile(auth) && userHasApplication(auth)
}

export function redirectToAuth(params?: AuthRedirectParams) {
  const authUrl = new URL(
    import.meta.env.VITE_ST_WEBSITE_BASE_PATH,
    import.meta.env.VITE_ST_WEBSITE_DOMAIN,
  )
  const searchParams = new URLSearchParams(params)
  authUrl.search = searchParams.toString()
  window.location.href = authUrl.href
}
