import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

import { NavCollapse } from './NavCollapse'
import { NavItem } from './NavItem'
import type { NavItemType } from './types'

import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

interface NavGroupProps {
  menuOpen: boolean
  onMenuClose: () => void
  item: NavItemType
}

export function NavGroup({ menuOpen, onMenuClose, item }: NavGroupProps) {
  const { roles } = useAuthentication()

  const theme = useTheme()

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    const dontShow =
      item.allowedRoles?.every((itemRole) => !roles.includes(itemRole)) === true

    if (dontShow) {
      return undefined
    }

    switch (menu.type) {
      case 'collapse':
        return (
          <NavCollapse
            menuOpen={menuOpen}
            onMenuClose={onMenuClose}
            key={menu.id}
            item={menu}
            level={1}
          />
        )
      case 'item':
        return (
          <NavItem
            menuOpen={menuOpen}
            onMenuClose={onMenuClose}
            key={menu.id}
            item={menu}
            level={1}
          />
        )
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return (
    <>
      {
        <>
          <List
            disablePadding={!menuOpen}
            subheader={
              item.title != null &&
              menuOpen && (
                <Typography
                  variant="caption"
                  sx={{ ...theme.typography.menuCaption }}
                  display="block"
                  gutterBottom
                >
                  {item.title}
                </Typography>
              )
            }
          >
            {items}
          </List>

          {/* group divider */}
          {menuOpen && <Divider sx={{ mt: 0.25, mb: 1.25 }} />}
        </>
      }
    </>
  )
}
