import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/system'
import { useCallback, useState } from 'react'

import { ChangeAvatarModal } from './ChangeAvatarModal'

import { Avatar } from '@/components/berry/Avatar'

export interface AvatarWithChangeModalProps {
  src: string | undefined
}

export function AvatarWithChangeModal({ src }: AvatarWithChangeModalProps) {
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setModalOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setModalOpen(false)
  }, [])
  return (
    <Box
      sx={{
        'position': 'relative',
        ':hover .change-avatar-overlay': {
          opacity: 1,
        },
      }}
    >
      <Avatar size="xl" src={src} />
      <Box
        className="change-avatar-overlay"
        onClick={handleOpen}
        sx={{
          opacity: 0,
          transition: '.3s ease',
          position: 'absolute',
          left: '-1%',
          top: '-1%',
          width: '102%',
          height: '102%',
          borderRadius: '50%',
          bgcolor: (theme) => alpha(theme.palette.background.default, 0.7),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          cursor: 'pointer',
        }}
      >
        <Typography variant="body1" fontWeight={600} color="primary">
          Change Avatar
        </Typography>
      </Box>
      <ChangeAvatarModal open={modalOpen} onClose={handleClose} />
    </Box>
  )
}
