import { type TypedDocumentNode, gql } from '@apollo/client'

import type {
  CreateAvatarUploadMutation,
  CreateAvatarUploadMutationVariables,
} from './createAvatarUpload.generated'

export const CREATE_AVATAR_UPLOAD_MUTATION: TypedDocumentNode<
  CreateAvatarUploadMutation,
  CreateAvatarUploadMutationVariables
> = gql`
  mutation CreateAvatarUpload($input: CreateAvatarUploadInput!) {
    createAvatarUpload(createAvatarUploadInput: $input) {
      bucketPath
      uploadUrl
    }
  }
`
