import InfoIcon from '@mui/icons-material/Info'
import ReceiptIcon from '@mui/icons-material/ReceiptLong'
import type { RouteObject } from 'react-router-dom'

import { createRoleProtectedRouteObject } from '../../helpers/createRoleProtectedRouteObject'

import { routes } from '@/configs/routes'
import { AccountSettingsLayout, DetailsLayout } from '@/layouts'

export const accountSettingsRoutes: RouteObject[] = [
  {
    element: (
      <AccountSettingsLayout>
        <DetailsLayout
          tabs={[
            {
              label: 'General',
              icon: <InfoIcon />,
              linkTo: routes.accountGeneral.path,
            },
            {
              label: 'Credit History',
              icon: <ReceiptIcon />,
              linkTo: routes.accountCreditHistory.path,
            },
          ]}
        />
      </AccountSettingsLayout>
    ),
    children: [
      createRoleProtectedRouteObject(
        routes.accountGeneral,
        async () => await import('@/pages/accountSettings/AccountGeneralPage'),
      ),
      createRoleProtectedRouteObject(
        routes.accountCreditHistory,
        async () => await import('@/pages/accountSettings/CreditHistoryPage'),
      ),
    ],
  },
]
