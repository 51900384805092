import { Outlet } from 'react-router-dom'

import { ErrorPage } from '../../pages/ErrorPage'

import { authRoutes } from './authRoutes'
import { AuthProtectedRoute } from './helpers/AuthProtectedRoute'
import { mainRoutes } from './mainRoutes'
import { otherRoutes } from './otherRoutes'

export const routerRoutes = [
  {
    errorElement: <ErrorPage />,
    element: <Outlet />,
    children: [
      {
        path: '/',
        element: <AuthProtectedRoute allowIf={() => false} />,
      },
      ...mainRoutes,
      ...authRoutes,
      ...otherRoutes,
    ],
  },
]
