import { ChangeAvatarForm } from './ChangeAvatarForm'

import { CardModal } from '@/components/CardModal'

export interface ChangeAvatarModalProps {
  open: boolean
  onClose: () => void
}

export function ChangeAvatarModal({ open, onClose }: ChangeAvatarModalProps) {
  return (
    <CardModal title="Change Avatar" open={open} onClose={onClose}>
      <ChangeAvatarForm onSuccess={onClose} />
    </CardModal>
  )
}
