import SuperTokens from 'supertokens-auth-react'
import Session from 'supertokens-auth-react/recipe/session'

SuperTokens.init({
  appInfo: {
    appName: import.meta.env.VITE_ST_APP_NAME,
    apiDomain: import.meta.env.VITE_ST_API_DOMAIN,
    websiteDomain: import.meta.env.VITE_ST_WEBSITE_DOMAIN,
    apiBasePath: import.meta.env.VITE_ST_API_BASE_PATH,
    websiteBasePath: import.meta.env.VITE_ST_WEBSITE_BASE_PATH,
  },
  recipeList: [
    Session.init({
      sessionTokenFrontendDomain: import.meta.env.VITE_ST_COOKIE_DOMAIN,
      sessionTokenBackendDomain: import.meta.env.VITE_ST_COOKIE_DOMAIN,
    }),
  ],
})
