import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import Grow from '@mui/material/Grow'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { MainCard } from '@/components/berry/MainCard'
import { routes } from '@/configs/routes'
import { fullNameFromProfile } from '@/lib/formatters/profileFormatters'
import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

export interface UserMenuPopperProps {
  anchorEl: HTMLDivElement | null
  open: boolean
  onClose: () => void
}

export function UserMenuPopper({
  anchorEl,
  open,
  onClose,
}: UserMenuPopperProps) {
  const theme = useTheme()
  const { currentUser, signOut } = useAuthentication()
  const navigate = useNavigate()

  const handleSignOut = useCallback(async () => {
    await signOut()
  }, [signOut])

  const handleNavigateToAccountSettings = useCallback(() => {
    navigate(routes.accountGeneral.path)
    onClose()
  }, [navigate, onClose])

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorEl?.focus()
    }

    prevOpen.current = open
  }, [anchorEl, open])
  return (
    <Popper
      placement="bottom"
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 14],
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={onClose}>
          <Grow in={open} {...TransitionProps}>
            <Box>
              <Paper>
                {open && (
                  <MainCard
                    border={false}
                    elevation={16}
                    boxShadow
                    shadow={theme.shadows[16]}
                  >
                    <Box>
                      <Stack pb={1}>
                        <Stack
                          direction="row"
                          spacing={0.5}
                          alignItems="center"
                        >
                          <Typography variant="h4">Good Morning,</Typography>
                          <Typography
                            component="span"
                            variant="h4"
                            sx={{ fontWeight: 400 }}
                          >
                            {fullNameFromProfile(currentUser?.profile)}
                          </Typography>
                        </Stack>
                        <Typography variant="subtitle2">
                          {currentUser?.profile?.email}
                        </Typography>
                      </Stack>
                      <Divider />
                    </Box>

                    <Box>
                      <List
                        component="nav"
                        sx={{
                          'width': '100%',
                          'maxWidth': 350,
                          'minWidth': 300,
                          'borderRadius': `8px`,
                          '& .MuiListItemButton-root': { mt: 0.5 },
                        }}
                      >
                        <ListItemButton
                          sx={{ borderRadius: `8px` }}
                          onClick={handleNavigateToAccountSettings}
                        >
                          <ListItemIcon>
                            <SettingsIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                Account Settings
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: `8px` }}
                          onClick={handleSignOut}
                        >
                          <ListItemIcon>
                            <LogoutIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </MainCard>
                )}
              </Paper>
            </Box>
          </Grow>
        </ClickAwayListener>
      )}
    </Popper>
  )
}
