import { useMemo } from 'react'
import type { LoadedSessionContext } from 'supertokens-auth-react/lib/build/recipe/session/types'
import {
  useClaimValue,
  useSessionContext,
} from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'

import type { UserMetadata, UserRole } from './types'

const EMPTY_ROLES: UserRole[] = []

interface UserMetadataPayload {
  fn?: string
  ln?: string
  av?: string
  em?: string
}

interface SessionData {
  session: LoadedSessionContext
  metadata: UserMetadata
  roles: UserRole[]
}

export function useSessionData(): SessionData | undefined {
  const session = useSessionContext()

  const roleClaims = useClaimValue(UserRoleClaim)
  const loadedRoleClaims = roleClaims.loading ? undefined : roleClaims
  const roles =
    (loadedRoleClaims?.value as UserRole[] | undefined) ?? EMPTY_ROLES

  const sessionData: SessionData | undefined = useMemo(() => {
    if (session.loading) {
      return undefined
    }

    const payload = session.accessTokenPayload as UserMetadataPayload

    return {
      session,
      metadata: {
        firstName: payload.fn,
        lastName: payload.ln,
        avatarUrl: payload.av,
        email: payload.em,
      },
      roles,
    }
  }, [session, roles])

  return sessionData
}
