import { useMutation } from '@apollo/client'
import { classValidatorResolver } from '@hookform/resolvers/class-validator'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import { captureException } from '@sentry/react'
import type { GraphQLFormattedError } from 'graphql'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { FormContainer, TextFieldElement } from 'react-hook-form-mui'

import { CREATE_AVATAR_UPLOAD_MUTATION } from './gql/createAvatarUpload'
import type { CreateAvatarUploadMutation } from './gql/createAvatarUpload.generated'
import { UPDATE_PROFILE_AVATAR_MUTATION } from './gql/updateProfileAvatar'
import { ChangeAvatarFormSchema } from './schema'

import { ButtonWithSpinner } from '@/components/ButtonWithSpinner'
import { Avatar } from '@/components/berry/Avatar'
import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

const validationResolver = classValidatorResolver(ChangeAvatarFormSchema)

export interface ChangeAvatarFormProps {
  onSuccess?: () => void
}

export function ChangeAvatarForm({ onSuccess }: ChangeAvatarFormProps) {
  const { currentUser } = useAuthentication()

  const [file, setFile] = useState<File>()
  const [fileData, setFileData] = useState<string>()
  const [uploading, setUploading] = useState(false)

  const [createAvatarUpload] = useMutation(CREATE_AVATAR_UPLOAD_MUTATION)
  const [updateAvatar] = useMutation(UPDATE_PROFILE_AVATAR_MUTATION)

  const changeHandler: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback((e) => {
    const newFile = (e.target as { files: FileList }).files[0]
    setFile(newFile)
  }, [])

  useEffect(() => {
    let fileReader: FileReader | null = null
    let isCancel = false
    if (file != null) {
      fileReader = new FileReader()
      fileReader.onload = (e) => {
        const { result } = e.target as { result: string | undefined }
        if (result != null && !isCancel) {
          setFileData(result)
        }
      }
      fileReader.readAsDataURL(file)
    }
    return () => {
      isCancel = true
      if (fileReader != null && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [file])

  const handleSubmit = useCallback(async () => {
    if (file == null) {
      return
    }

    let data: CreateAvatarUploadMutation | null | undefined = null
    let errors: readonly GraphQLFormattedError[] | undefined | null = null

    setUploading(true)

    try {
      const fetchResult = await createAvatarUpload({
        variables: {
          input: {
            contentLength: file.size,
            contentType: file.type,
            filename: file.name,
          },
        },
      })

      data = fetchResult.data
      errors = fetchResult.errors
    } catch (e) {
      enqueueSnackbar('Failed to upload avatar', { variant: 'error' })
      captureException(e)
      setUploading(false)
      return
    }

    if (errors != null || data == null) {
      enqueueSnackbar('Failed to upload avatar', { variant: 'error' })
      setUploading(false)
      return
    }

    const { bucketPath, uploadUrl } = data.createAvatarUpload
    const response = await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
        'Content-Length': file.size.toString(),
      },
    })

    if (!response.ok) {
      enqueueSnackbar('Failed to upload avatar', { variant: 'error' })
      setUploading(false)
      return
    }

    try {
      const { data, errors } = await updateAvatar({
        variables: {
          input: {
            avatarBucketPath: bucketPath,
          },
        },
      })

      if (errors != null || data == null) {
        enqueueSnackbar('Failed to update avatar', { variant: 'error' })
        setUploading(false)
        return
      }
    } catch (e) {
      enqueueSnackbar('Failed to update avatar', { variant: 'error' })
      setUploading(false)
      captureException(e)
      return
    }

    enqueueSnackbar('Avatar updated', { variant: 'success' })
    setUploading(false)
    onSuccess?.()
  }, [createAvatarUpload, file, onSuccess, updateAvatar])

  return (
    <FormContainer onSuccess={handleSubmit} resolver={validationResolver}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid size={12} justifyContent="center" display="flex">
            <Avatar
              size="xxl"
              src={fileData ?? currentUser?.profile?.avatarUrl ?? undefined}
            />
          </Grid>
          <Grid size={12} justifyContent="center" display="flex">
            <Stack spacing={2} sx={{ width: '100%' }}>
              <TextFieldElement
                name="image"
                type="file"
                slotProps={{ htmlInput: { accept: 'image/*' } }}
                onChange={changeHandler}
              />
              <ButtonWithSpinner
                type="submit"
                variant="contained"
                fullWidth
                loading={uploading}
              >
                Upload
              </ButtonWithSpinner>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </FormContainer>
  )
}
