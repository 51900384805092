import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { type PropsWithChildren, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { AvatarWithChangeModal } from './components/AvatarWithChangeModal'

import { LoadingSpinner } from '@/components/LoadingSpinner'
import { routes } from '@/configs/routes'
import { centsToFormattedCurrency } from '@/lib/formatters/currencyFormatters'
import { fullNameFromProfile } from '@/lib/formatters/profileFormatters'
import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

export type AccountSettingsLayoutProps = PropsWithChildren

export function AccountSettingsLayout({
  children,
}: AccountSettingsLayoutProps) {
  const { currentUser } = useAuthentication()
  const navigate = useNavigate()

  const navigateToAddCredits = useCallback(() => {
    navigate(routes.addCredits.path)
  }, [navigate])

  if (currentUser == null) {
    return <LoadingSpinner />
  }

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, sm: 4, lg: 3 }}>
        <Stack spacing={3}>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Box justifyContent="center" display="flex">
                  <AvatarWithChangeModal
                    src={currentUser.profile?.avatarUrl ?? undefined}
                  />
                </Box>
                <Box>
                  <Typography textAlign="center">
                    {fullNameFromProfile(currentUser.profile)}
                  </Typography>
                  <Typography textAlign="center" variant="subtitle2">
                    {currentUser.profile?.email}
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Stack spacing={3}>
                <Stack spacing={1}>
                  <Typography textAlign="center" variant="h4">
                    Credit Balance
                  </Typography>
                  <Typography textAlign="center" variant="h3">
                    {centsToFormattedCurrency(currentUser.creditBalance)}
                  </Typography>
                </Stack>
                <Button variant="contained" onClick={navigateToAddCredits}>
                  Add Credits
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, sm: 8, lg: 9 }}>{children}</Grid>
    </Grid>
  )
}
