import type { UserProfile } from '@/graphqlTypes.generated'

export type UserProfileNames = Pick<
  UserProfile,
  'firstName' | 'middleName' | 'lastName' | 'suffix'
>

export function fullNameFromProfile(profile: UserProfileNames): string
export function fullNameFromProfile(
  profile?: UserProfileNames | null,
): string | undefined
export function fullNameFromProfile(
  profile?: UserProfileNames | null,
): string | undefined {
  if (profile == null) {
    return undefined
  }

  const { firstName, middleName, lastName, suffix } = profile
  return (
    firstName +
    (middleName != null ? ` ${middleName}` : '') +
    ` ${lastName}` +
    (suffix != null ? `, ${suffix}` : '')
  )
}

export function initialsFromProfile(profile: UserProfileNames): string
export function initialsFromProfile(
  profile?: UserProfileNames | null,
): string | undefined
export function initialsFromProfile(
  profile?: UserProfileNames | null,
): string | undefined {
  if (profile == null) {
    return undefined
  }

  const { firstName, lastName } = profile
  const firstInitial = firstName[0]
  const lastInitial = lastName[0]
  return `${firstInitial}${lastInitial}`
}
