import { useMemo } from 'react'
import { Navigate, type To } from 'react-router-dom'

import { routes } from '@/configs/routes'
import {
  redirectToAuth,
  userHasProfile,
  userRequiresApplication,
  userWaitingOnApplication,
} from '@/lib/loginFlowHelpers'
import { useAuthentication } from '@/providers/AuthenticationProvider/useAuthentication'

export function AuthRedirector() {
  const auth = useAuthentication()

  const redirectTo: To | null = useMemo(() => {
    if (!auth.session.doesSessionExist) {
      return null
    }
    if (userRequiresApplication(auth)) {
      return routes.createProfile.path
    }

    if (userWaitingOnApplication(auth)) {
      return routes.applicationPending.path
    }

    if (userHasProfile(auth)) {
      return routes.dashboard.path
    }

    throw new Error('Unexpected user state')
  }, [auth])

  if (redirectTo == null) {
    redirectToAuth([['redirectUrl', window.location.toString()]])
    return
  }

  return <Navigate replace to={redirectTo} />
}
